/* AddressPage.css */
.suggestion-item {
    font-size: 14px; /* Adjust the font size as needed */
  }
  .dropdown-container {
    position: relative;
  }
  
  .dropdown-suggestions {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 150px; /* Adjust the maximum height as needed */
    overflow-y: auto;
    z-index: 1;
  }