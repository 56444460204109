  
.dropdown-container {
  position: relative;
  width: 100%;
}

.dropdown-suggestions {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f5f5f5;
}
